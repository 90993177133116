import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SuccessAlert from './SuccessAlert/SuccessAlert';

const MintNow = ({ setWeb3Data, web3Data }) => {
    const [mintNum, setMintNum] = useState(1);
    const [disableBtn, setDisableBtn] = useState({ left: true, right: false });
    const [isLoading, setIsLoading] = useState(false);
    const mintValue = useRef();
    const [open, setOpen] = useState(false);

    const doMint = async () => {
        try {
            setIsLoading(true);
            const { accounts, contract } = web3Data;
            let mintAmount = mintNum;
            const price = await contract.methods.cost().call();
            await contract.methods.mint(mintAmount).send({ from: accounts[0], value: price * mintAmount });
            const response = await contract.methods.cost().call();
            if (response) { setOpen(true) }
            setIsLoading(false);
            setWeb3Data(data => ({ ...data, storageValue: response }));
        }
        catch (err) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (mintNum === 1) setDisableBtn({ left: true, right: false });
        else if (mintNum === 5) setDisableBtn({ left: false, right: true });
        else setDisableBtn({ left: false, right: false });
    }, [mintNum]);

    return (
        <div className='plusMinusBtns-wrapper'>
            <div className='plusMinusBtns-wrapper-left'>
                <button className='plus-btn'
                    disabled={disableBtn.left}
                    style={{ border: disableBtn.left ? '1px solid #B9B9B9' : '1px solid #4F48EC' }}
                    onClick={() => setMintNum(num => {
                        if (num > 1) return num - 1;
                        return num;
                    })}>
                    -
                </button>
                <span ref={mintValue}>{mintNum}</span>
                <button className='minus-btn'
                    disabled={disableBtn.right}
                    style={{ border: disableBtn.right ? '1px solid #B9B9B9' : '1px solid #4F48EC' }}
                    onClick={() => setMintNum(num => {
                        if (num < 5) return num + 1;
                        return num;
                    })} >
                    +
                </button>
            </div>
            <div className='plusMinusBtns-wrapper-right'>
                {isLoading ?
                    <div className='loader-wrapper'>
                        <CircularProgress size={25} sx={{ color: '#4F48EC' }} />
                    </div>
                    : <button className='mint-now-btn'
                        onClick={doMint}
                        disabled={web3Data.accounts?.length > 0 ? false : true}>
                        Mint now
                    </button>}
            </div>
            <SuccessAlert open={open} setOpen={setOpen} />
        </div>
    );
}

export default MintNow;
