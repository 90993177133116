import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../lotties/wave.json';

const Logo = ({ direction }) => {
    const defaultOptions = {
        loop: false,
        autoplay: false,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Lottie options={defaultOptions}
            direction={direction}
            width="175px" />
    )
}

export default Logo
