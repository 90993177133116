import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';
import successImage from '../../../Assets/img/confimg.png';
import discordIcon from '../../../Assets/img/discordIcon.png';
import './SuccessAlert.css';

const SuccessAlert = ({ open, setOpen }) => {
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box>
                <div class="comfirmationBoxArea">
                    <div className="comfirmationBox">
                        <img src={successImage} alt="SuccessImage" />
                        <h3>Awesome !</h3>
                        <p>
                            Thank you so much for the support, and welcome to The Floaters community!
                        </p>
                        <br />
                        <a href="https://www.thefloaters.io/" target="_blank">
                            <button type="button" className="returnBtn">
                                Return to the website
                            </button>
                        </a>
                        <br />
                        <a href="https://discord.gg/g7TqCMaCv5" target="_blank" style={{ textDecoration: 'none' }}>
                            <button type="button" className="discrotBtn">
                                <img src={discordIcon} alt="discordIcon" style={{ width: 'auto' }} />
                                Join Our Discord
                            </button>
                        </a>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default SuccessAlert;
