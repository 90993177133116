import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import React from 'react';

const ConnectWall = ({ hitWeb3, web3Data }) => {
    return (
        <div className="connect-wall-right-top">
            <h2>1. Connect your wallet.</h2>
            {
                web3Data.accounts?.length > 0 ?
                    <button className='connect-wall-button' disabled={true} style={{ cursor: 'not-allowed' }}><AccountBalanceWalletIcon /> Connected</button> :
                    <button className='connect-wall-button' onClick={() => hitWeb3()}><AccountBalanceWalletIcon /> Connect Wallet</button>
            }
        </div>
    );
}

export default ConnectWall;
