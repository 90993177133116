import { Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import wallImage from '../../Assets/img/connect-wall.png';
import Logo from '../../Assets/Logo/Logo';
import ConnectWall from './ConnectWall';
import abi from './MintNeeds/abi.json';
import getWeb3 from './MintNeeds/getWeb3';
import MintNow from './MintNow';
import './MintPop.css';


const MintPop = () => {
    const [direction, setDirection] = useState(-1);
    const [web3Data, setWeb3Data] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [toaster, setToaster] = useState({ open: false, message: '' });

    const hitWeb3 = async () => {
        try {
            setIsLoading(true);
            // Get network provider and web3 instance.
            const web3 = await getWeb3();

            // Use web3 to get the user's accounts.
            const accounts = await web3.eth.getAccounts();
            if (accounts.length > 0) {
                const contract = await new web3.eth.Contract(
                    abi,
                    process.env.REACT_APP_CONTRACT_ADDRESS,
                );
                setWeb3Data({ web3, accounts, contract });
            }
            setIsLoading(false);
        }
        catch (err) {
            setIsLoading(false)
            alert('Something went wrong while getting accoutns.')
            console.error(err);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        let web3;
        if (window.ethereum) web3 = new Web3(window.ethereum);
        else if (window.web3) web3 = window.web3;

        if (web3) {
            web3.eth.getAccounts()
                .then(async (data) => {
                    if (data.length > 0) {
                        const contract = await new web3.eth.Contract(
                            abi,
                            process.env.REACT_APP_CONTRACT_ADDRESS,
                        );
                        setWeb3Data({ web3, accounts: data, contract });
                    }
                })
                .finally(() => setIsLoading(false));
            window.ethereum.on('accountsChanged', async (accounts) => {
                if (accounts.length > 0) {
                    const contract = await new web3.eth.Contract(
                        abi,
                        process.env.REACT_APP_CONTRACT_ADDRESS,
                    );
                    setWeb3Data({ web3, accounts, contract });
                } else {
                    setWeb3Data({});
                }
            });
        }
    }, [])

    return (
        <>
            <div className="mintPopwrapper">
                <div className='connect-wall-wrapper'>
                    <div className="connect-wall-left">
                        <h2>
                            Get yourself an OG Floater ✨
                        </h2>
                        <p>
                            Each floater was observed on a different day, time and weather. We made each NFT individually with love.
                        </p>
                        <img src={wallImage} alt="connectWallImage" />
                    </div>
                    <div className="connect-wall-right">
                        <ConnectWall hitWeb3={hitWeb3} web3Data={web3Data} />
                        <div className='plusMinusBtns'>
                            <h2>2. Amount you would like to mint.</h2>
                            <MintNow setWeb3Data={setWeb3Data} web3Data={web3Data} setToaster={setToaster} />
                        </div>
                        <div className='connect-wall-right-bottom'>
                            <h2>3. Future OG holder event!</h2>
                            <p>OG holder will have a chance to mint a customizable floater after the phase plan is unlocked!</p>
                        </div>
                    </div>
                </div>
                <div className='connect-wall-bottom'>
                    <div className='connect-wall-bottom-left'>
                        <span className='modal-poweredby'>
                            Powered by
                        </span>
                        <span className='modal-floaters' onMouseOver={() => setDirection(1)} onMouseLeave={() => setDirection(-1)}>
                            <Logo direction={direction} />
                        </span>
                    </div>
                    <div className='connect-wall-bottom-right'>
                        <a href="https://www.thefloaters.io" target="_blank">Return to website</a>
                    </div>
                </div>
                <br />
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={toaster.open}
                autoHideDuration={3000}
                onClose={() => setToaster(toaster => ({ ...toaster, open: false }))}
                message={toaster.message}
                key={'topRight'}
            />
        </>
    )
}

export default MintPop
