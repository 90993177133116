import React from 'react';
import './App.css';
import MintPop from './Components/MintPop/MintPop';

const App = () => {
    return (
        <div id='root'>
            <MintPop />
        </div>
    )
}

export default App
